import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import { Theme } from './src/styles/settings/theme'
import { GlobalStyles } from './src/styles/global'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={Theme}>
      <Global styles={GlobalStyles} />
      {element}

      {process.env.NODE_ENV === 'development' ? null : (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function() {
              var didInit = false;
              function initMunchkin() {
                if(didInit === false) {
                  didInit = true;
                  Munchkin.init('667-CDO-976');
                }
              }
              var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = '//munchkin.marketo.net/munchkin.js';
              s.onreadystatechange = function() {
                if (this.readyState == 'complete' || this.readyState == 'loaded') {
                  initMunchkin();
                }
              };
              s.onload = initMunchkin;
              document.getElementsByTagName('head')[0].appendChild(s);
            })();
        `,
          }}
        />
      )}
    </ThemeProvider>
  )
}
