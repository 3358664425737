import { css } from '@emotion/core'

import FontFamily from '../settings/fonts'
import { Theme } from '../settings/theme'

// import { Overrides } from './overrides'

export const Base = css`
  ${FontFamily}

  * {
    // outline-color: ${Theme.colors.accentLight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }

  html,
  body {
    height: 100%;
    padding-bottom: 0;
    margin: 0;
    font-family: ${Theme.fonts.primary};
    font-size: ${Theme.fontsizes.s};
    color: ${Theme.colors.black};
  }

  h1,
  h2,
  h3 {
    margin: 0;

  }

  a {
    color: ${Theme.colors.black};
    text-decoration: none;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`
