import { ITheme } from '../../types'

const Xs = '31.25rem'
const Sm = '46.25rem'
const Md = '56.25rem'
const Lg = '67.5rem'
const Xl = '75rem'
const Xxl = '96.25rem'

const colors = {
  white: '#ffffff',
  black: '#36454F',
  darkBlue: '#0066CA',
  lightBlue: '#F6F9FC',
  red: 'red',
  gray1: '#8C969F',
  darkGray: '#D0D2D3',
  purple: '#7267E6',
  green: '#84BA65',
  aqua: '#2CC8BD',
  babyBlue: '#0FC9E8',
  blue: '#0091F0',
}

const fontsizes = {
  xl: '3.4rem', // 60px
  l: '2.4rem', // 42px
  m: '1.8rem', // 32px
  s: '1.1rem', // 19px
  xs: '0.95rem', // 17px
  xxs: '0.85rem', // 15px
}

const lineHeight = {
  xl: '2',
  l: '1.5',
  m: '1.3',
  s: '1.2',
}

const baseUnit = 1

export const Theme: ITheme = {
  basicTransition: 'all 0.2s ease-in-out',
  breakpoints: [Sm, Md, Lg, Xl, Xxl],
  colors,
  fontsizes,
  lineHeight,
  spacing: {
    xxs: baseUnit * 0.5 + 'rem',
    xs: baseUnit * 0.75 + 'rem',
    s: baseUnit + 'rem',
    m: baseUnit * 1.2 + 'rem',
    l: baseUnit * 1.4 + 'rem',
    xl: baseUnit * 1.6 + 'rem',
    xxl: baseUnit * 1.8 + 'rem',
  },
  fonts: {
    primary: 'Quicksand, Arial, sans-serif',
    // secondary: 'Arial',
  },
  fontweight: {
    heavy: '900',
    medium: '700',
    normal: '400',
    light: '200',
  },
  cardBorderRadius: '8px',
  maxPageWidth: '75rem',
  borderRadius: '0.625rem',
  cardBoxShadow: '0px 15px 25px rgba(54, 69, 79, 0.15)',
}

interface IBreakpoints {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
  [key: string]: string
}
const breakpoints: IBreakpoints = {
  lg: Lg,
  md: Md,
  sm: Sm,
  xl: Xl,
  xs: Xs,
  xxl: Xxl,
}

export const Mq = Object.keys(breakpoints).reduce((acc, breakKey) => {
  return {
    ...acc,
    [breakKey]: `@media (min-width: ${breakpoints[breakKey]})`,
  }
}, {} as IBreakpoints)

export type ThemeColors = keyof typeof Theme.colors
