// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-globalscale-tsx": () => import("./../../../src/pages/globalscale.tsx" /* webpackChunkName: "component---src-pages-globalscale-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplaces-tsx": () => import("./../../../src/pages/marketplaces.tsx" /* webpackChunkName: "component---src-pages-marketplaces-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-terms-of-use-en-tsx": () => import("./../../../src/pages/terms-of-use/en.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-en-tsx" */),
  "component---src-pages-travel-tsx": () => import("./../../../src/pages/travel.tsx" /* webpackChunkName: "component---src-pages-travel-tsx" */),
  "component---src-pages-yapzone-tsx": () => import("./../../../src/pages/yapzone.tsx" /* webpackChunkName: "component---src-pages-yapzone-tsx" */),
  "component---src-templates-legal-center-tsx": () => import("./../../../src/templates/LegalCenter.tsx" /* webpackChunkName: "component---src-templates-legal-center-tsx" */),
  "component---src-templates-media-detail-tsx": () => import("./../../../src/templates/MediaDetail.tsx" /* webpackChunkName: "component---src-templates-media-detail-tsx" */)
}

